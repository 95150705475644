/*import { Counter } from "./components/Counter";*/
import { FetchData } from "./components/FetchData";
/*import { Home } from "./components/Home";*/

const AppRoutes = [
    //{
    //  index: true,
    //  element: <Home />
    //},
    //{
    //  path: '/counter',
    //  element: <Counter />
    //},
    {
        index: true,
        /*path: '/fetch-data',*/
        element: <FetchData />
    }
];

export default AppRoutes;
