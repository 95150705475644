import React, { Component } from 'react';

function groupByCategory(items, category) {
    return items.reduce(function (rv, x) {
        (rv[x[category]] = rv[x[category]] || []).push(x);
        return rv;
    }, {});

    return items;
}

export class FetchData extends Component {
    static displayName = FetchData.name;

    constructor(props) {
        super(props);
        this.state = { surgeries: [], loading: true };
    }

    componentDidMount() {
        this.populateSurgeriesData();
    }

    static renderSurgeriesAccordions(categories) {
        var counter = 0;
        return (
            <div class="accordion" id="surgeriesAccordion">
                {
                    Object.keys(categories).map(key => {
                        counter++;
                        const tmp = { name: key, value: categories[key], id: "id_" + counter };
                        return <SurgeryAccordion category={tmp} />
                    })
                }
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Nahrávání...</em></p>
            : FetchData.renderSurgeriesAccordions(groupByCategory(this.state.surgeries, "department"));

        return (
            <div>
                <h6 id="tabelLabel" >Seznam ordinací a jejich ordinační doby</h6>
                <div>{contents}</div>
            </div>
        );
    }

    async populateSurgeriesData() {
        const response = fetch('data.json'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                console.log(response)
                return response.json();
            })
            .catch(function (error) {
                console.log();
            });

        const data = await response;
        this.setState({ surgeries: data.surgeries, loading: false });
    }
}

function SurgeriesTable(props) {
    return (
        <div class="table-responsive-sm">
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Jméno</th>
                        <th>Podlaží</th>
                        <th>Telefon</th>
                        <th>Email</th>
                        <th>Ordinační hodiny</th>
                    </tr>
                </thead>
                <tbody>
                    {props.surgeries.map(surgery =>
                        <tr key={surgery.name}>
                            <td>{surgery.name}</td>
                            <td>{surgery.place}</td>
                            <td>{surgery.phone}</td>
                            <td>{surgery.email}</td>
                            {!surgery.www ? <td><OpeningHours openingHours={surgery.openingHours} /></td> : <td class='text-break'><a target='_blank' href={surgery.www}>{surgery.www}</a></td>}
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

function Surgery(props) {
    return (
        <div>
            <div class="row g-5">
                <div class="col-md-2"><strong>Jméno</strong></div>
                <div class="col-md-2"><strong>Podlaží</strong></div>
                <div class="col-md-2"><strong>Telefon</strong></div>
                <div class="col-md-2"><strong>Email</strong></div>
                <div class="col-md-4"><strong>Ordinační hodiny</strong></div>
            </div>
            {props.surgeries.map(surgery =>
                <div class="row g-5" key={surgery.name}>
                    <div class="col-md-2">{surgery.name}</div>
                    <div class="col-md-2">{surgery.place}</div>
                    <div class="col-md-2">{surgery.phone}</div>
                    <div class="col-md-2 text-break">{surgery.email}</div>
                    <div class="col-md-4"><OpeningHours openingHours={surgery.openingHours} /></div>
                    {/*{surgery.www == null || surgery.www == "" ? <div class="col-md-4"><OpeningHours openingHours={surgery.openingHours} /></div> : <div class='text-break col-md-4'><a target='_blank' href={surgery.www}>{surgery.www}</a></div>}*/}
                </div>
            )}
        </div>
    );
}

function SurgeryAccordion(props) {
    const heading = "heading_" + props.category.id;
    const id = props.category.id;
    const idWithHash = "#" + id;

    return (
        <div class="accordion-item">
            <h2 class="accordion-header" id={heading}>
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={idWithHash} aria-expanded="true" aria-controls={id}>
                    {props.category.name}
                </button>
            </h2>
            <div id={id} class="accordion-collapse collapse" aria-labelledby={heading} data-bs-parent="#surgeriesAccordion">
                <div class="accordion-body">
                    <SurgeriesTable surgeries={props.category.value} />
                </div>
            </div>
        </div>
    );
}

//function OpeningHours(props) {
//    try {
//        return(
//            <div>
//                {props.openingHours.map((day) => {
//                    if (day && day.monday) {
//                        let monday = { title: 'Pondělí', value: day.monday }
//                        try {
//                            <OpeningHourDay {...monday} />;
//                        }
//                        catch {

//                        }
//                    }
//                    else if (day && day.tuesday) {
//                        let tuesday = { title: 'Úterý', value: day.tuesday }
//                        try {
//                            <OpeningHourDay {...tuesday} />;
//                        }
//                        catch {

//                        }
//                    }
//                    else if (day && day.wednesday) {
//                        let wednesday = { title: 'Středa', value: day.wednesday }
//                        try {
//                            <OpeningHourDay {...wednesday} />;
//                        }
//                        catch {

//                        }
//                    }
//                    else if (day && day.thursday) {
//                        let thursday = { title: 'Čtvrtek', value: day.thursday }
//                        try {
//                            <OpeningHourDay {...thursday} />;
//                        }
//                        catch {

//                        }
//                    }
//                    else if (day && day.friday) {
//                        let friday = { title: 'Pátek', value: day.friday }
//                        try {
//                            <OpeningHourDay {...friday} />;
//                        }
//                        catch {

//                        }
//                    }
//                })}
//            </div>);

//    }
//    catch { return (<div>Nevyplněno</div>) }
//}

//function OpeningHours(props) {
//    var results = [];

//    try {
//        props.openingHours.map((day) => {
//            if (day && day.monday) {
//                let monday = { title: 'Pondělí', value: day.monday }
//                try {
//                    results.push(<OpeningHourDay {...monday} />);
//                }
//                catch {

//                }
//            }
//            else if (day && day.tuesday) {
//                let tuesday = { title: 'Úterý', value: day.tuesday }
//                try {
//                    results.push(<OpeningHourDay {...tuesday} />);
//                }
//                catch {

//                }
//            }
//            else if (day && day.wednesday) {
//                let wednesday = { title: 'Středa', value: day.wednesday }
//                try {
//                    results.push(<OpeningHourDay {...wednesday} />);
//                }
//                catch {

//                }
//            }
//            else if (day && day.thursday) {
//                let thursday = { title: 'Čtvrtek', value: day.thursday }
//                try {
//                    results.push(<OpeningHourDay {...thursday} />);
//                }
//                catch {

//                }
//            }
//            else if (day && day.friday) {
//                let friday = { title: 'Pátek', value: day.friday }
//                try {
//                    results.push(<OpeningHourDay {...friday} />);
//                }
//                catch {

//                }
//            }
//        });

//        return (<div>{results.map((day) => { <div class='row g-3'>day</div> })}</div>);
//    }
//    catch { return (<div>Nevyplněno</div>) }
//}

//function OpeningHourDay({ title, value }) {
//    try {
//        return
//        (
//            <div class='row g-3'>
//                <div class="col-auto">{title}</div>
//                <div class='text-start col-auto'>
//                    {value !== null ? value.morning.from : ""} {value !== null && value.morning.to !== "" ? " - " + value.morning.to : ""}
//                </div>
//                <div class='text-center col-auto'> {value !== null && value.afternoon.from ? " | " : ""} </div>
//                <div class='text-end col-auto'>
//                    {value !== null ? value.afternoon.from : ""} {value !== null && value.afternoon.to !== "" ? " - " + value.afternoon.to : ""}
//                </div>
//            </div>
//        )
//    }
//    catch {
//        return (<div class='row g-3'>Nevyplněno</div>)
//    }
//}

function OpeningHours(props) {
    var results = [];

    try {
        props.openingHours.map((hour) => {
            if (hour && hour.monday) {
                results.push(
                    <tr>
                        <td>Pondělí: </td>
                        <td class='text-start'>
                            {hour.monday !== null ? hour.monday.morning.from : ""} {hour.monday !== null && hour.monday.morning.to !== "" ? " - " + hour.monday.morning.to : ""}
                        </td>
                        <td class='text-center'> {hour.monday !== null && hour.monday.afternoon.from ? " | " : ""} </td>
                        <td class='text-end'>
                            {hour.monday !== null ? hour.monday.afternoon.from : ""} {hour.monday !== null && hour.monday.afternoon.to !== "" ? " - " + hour.monday.afternoon.to : ""}
                        </td>
                    </tr>)
            }
            else if (hour && hour.tuesday) {
                results.push(
                    <tr><td>Úterý: </td>
                        <td class='text-start'>
                            {hour.tuesday !== null ? hour.tuesday.morning.from : ""} {hour.tuesday !== null && hour.tuesday.morning.to !== "" ? " - " + hour.tuesday.morning.to : ""}
                        </td>
                        <td class='text-center'> {hour.tuesday !== null && hour.tuesday.afternoon.from ? " | " : ""} </td>
                        <td class='text-end'>
                            {hour.tuesday !== null ? hour.tuesday.afternoon.from : ""} {hour.tuesday !== null && hour.tuesday.afternoon.to !== "" ? " - " + hour.tuesday.afternoon.to : ""}
                        </td>
                    </tr>
                )
            }
            else if (hour && hour.wednesday) {
                results.push(
                    <tr>
                        <td>Středa: </td>
                        <td class='text-start'>
                            {hour.wednesday !== null ? hour.wednesday.morning.from : ""} {hour.wednesday !== null && hour.wednesday.morning.to !== "" ? " - " + hour.wednesday.morning.to : ""}
                        </td>
                        <td class='text-center'> {hour.wednesday !== null && hour.wednesday.afternoon.from ? " | " : ""} </td>
                        <td class='text-end'>
                            {hour.wednesday !== null ? hour.wednesday.afternoon.from : ""} {hour.wednesday !== null && hour.wednesday.afternoon.to !== "" ? " - " + hour.wednesday.afternoon.to : ""}
                        </td>
                    </tr>
                )
            }
            else if (hour && hour.thursday) {
                results.push(
                    <tr>
                        <td>Čtvrtek: </td>
                        <td class='text-start'>
                            {hour.thursday !== null ? hour.thursday.morning.from : ""} {hour.thursday !== null && hour.thursday.morning.to !== "" ? " - " + hour.thursday.morning.to : ""}
                        </td>
                        <td class='text-center'> {hour.thursday !== null && hour.thursday.afternoon.from ? " | " : ""} </td>
                        <td class='text-end'>
                            {hour.thursday !== null ? hour.thursday.afternoon.from : ""} {hour.thursday !== null && hour.thursday.afternoon.to !== "" ? " - " + hour.thursday.afternoon.to : ""}
                        </td>
                    </tr>
                )
            }
            else if (hour && hour.friday) {
                results.push(
                    <tr>
                        <td>Pátek: </td>
                        <td class='text-start'>
                            {hour.friday !== null ? hour.friday.morning.from : ""} {hour.friday !== null && hour.friday.morning.to !== "" ? " - " + hour.friday.morning.to : ""}
                        </td>
                        <td class='text-center'> {hour.friday !== null && hour.friday.afternoon.from ? " | " : ""} </td>
                        <td class='text-end'>
                            {hour.friday !== null ? hour.friday.afternoon.from : ""} {hour.friday !== null && hour.friday.afternoon.to !== "" ? " - " + hour.friday.afternoon.to : ""}
                        </td>
                    </tr>
                )
            }
        });

        return (
            <div class="table-responsive-sm">
                <table width='100%' class='text-right'><tbody>{results}</tbody></table>
            </div>
        );
    }
    catch { return (<table width='100%'><tbody></tbody></table>); }
}