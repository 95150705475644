import React, { Component } from 'react';

export class Footer extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">                
                <p class="col-md-4 mb-0 text-end">© 2022 www.dzji.cz</p>
                <ul class="nav col-md-4 text-start">
                    <li class="nav-item"><a href="mailTo:admin@dzji.cz">admin@dzji.cz</a></li>
                </ul>
            </footer>
        );
    }
}
